import React from "react";
import { useState } from "react";
import { Wrapper, InputWrapper, ButtonWrapper, ListWrapper, Separator, FormFieldWrapper,LandingSlider} from "./Landing.module.css";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Link } from "gatsby"
import { GrayTitle } from "./../../../css/Title.module.css";
import Button from "./../../../components/Button";
import EventCardExpo from "../../../components/EventCardExpo";
import Landing1 from "../../../res/landing/landing-marseille.png"
import Landing2 from "../../../res/landing/landing-winter.png"

import CarrouselCartel from "../../../components/CarrouselCartel";
import PhotoduMoment1 from "../../../res/Photo du moment/Photo 1.png"
import PhotoduMoment2 from "../../../res/Photo du moment/Photo 2.jpeg"
import PhotoduMoment3 from "../../../res/Photo du moment/Photo 3.jpeg"
import NF1 from "../../../res/landing/virtual-visit.png"
import NF2 from "../../../res/landing/winter-tile.png"
import UTILE from "../../../res/unleashedspray/tile.jpg"
import CTILE from "../../../res/crash/tile-2.png"
import PTILE from "../../../res/summer/slide-piercing.jpg"
import SSLIDE from "../../../res/summer/slide.gif"
import STILE from "../../../res/summer/tile.gif"

import addToMailchimp from 'gatsby-plugin-mailchimp'


// import FeedInstagram from "../../../components/FeedInstagram";


const Landing = () => {


  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };


  const exhibitions = [

    {
      date: "06 juin - 31 août 2024",
      title: "HIGH SUMMER VIBES",
      link: "",
      artist: "John CRASH Matos (NYC, 1961)",
      place: "GHOST GALERIE x CHÂTEAU DE LA GAUDE - Aix-en-Provence",
      description: "Exposition Hors les Murs à Aix-en-Provence. John CRASH Matos. GHOST GALERIE x CHÂTEAU DE LA GAUDE. HIGH SUMMER VIBES. Oeuvres des années 80 à nos jours. Visite gratuite tous les jours de l'été. Coffret signé & numéroté / 25 ex réunissant les 4 prints numéroté de 1 à 25. 4 prints édités pour l'occasion / 50 ex chaque version. La réunion des 4 prints forme l'oeuvre originale accrochée pour l'exposition",
      presentation_photo: CTILE,
      photos_paths: ["path", "path", "path", "path"],
    },
    {
      date: "Été 2024",
      title: "ENTWINED VISIONS",
      link: "",
      artist: "Futura (2000), Richard Hambleton, Invader",
      place: "Marseille",
      description: "Dans le monde captivant de l'art contemporain, l'union entre l'imaginaire et la réalité prend forme à travers les créations uniques des artistes, invitant les spectateurs à plonger dans un univers empreint de fascination. Au cœur de l'exposition estivale \"Entwined Visions\" se dévoile le talent éclatant de trois artistes contemporains : Richard Hambleton, Futura (2000) et Invader. Leurs œuvres révèlent non seulement leur perception singulière du monde, mais également la façon dont elles s'entrelacent pour former un paysage artistique dynamique et foisonnant.",
      presentation_photo: PTILE,
      photos_paths: ["path", "path", "path", "path"],
    },
    {
      date: "Été 2024",
      title: "NEW ADDRESS OPENING THIS SUMMER !",
      link: "",
      artist: "",
      place: "Paris",
      description: "GHOST GALERIE PARIS - 11 rue de Miromesnil, 75008 Paris",
      presentation_photo: STILE,
      photos_paths: ["path", "path", "path", "path"],
    },
  ];

  const exhibitionsToCome = [
    // {
    //   date: "13 Octobre - 18 Novembre 2023",
    //   title: "REMINISCENCE",
    //   link: "../../fr-projects",
    //   artist: "",
    //   place: "Paris",
    //   description:
    //   "Ghost Galerie est heureuse de vous présenter la seconde exposition de son programme liée à la jeune scène artistique : Ghost Project. Reminiscence, qui se déroulera du 13 octobre au 18 novembre prochain, présentera le travail et l'univers de 5 artistes : Hakim Sahiri, Clémence Gbonon, Djabril Boukhenaïssi, Félix Taburet & Hannah Becquante",
    //   presentation_photo: RTILE,
    //   photos_paths: ["path", "path", "path", "path"],
    // },
  ];

  const nouvellesAcquisitions = 
    {
      photos: [
        { src: PhotoduMoment1, name: "Futura 2000", artist: "FUTURA \n(Leonard McGurr dit) (né en 1955)", title:"Untitled (Atmos), 1995", desc1:"Peinture aérosol sur toile", desc2:"Spray paint on canvas", desc3:"135 x 150 cm", desc4:"53 1⁄8 x 59 in.", desc5:"140 x 155 cm (encadrée)", desc6:"55 x 61 in. (framed)", desc7: "- Collection particulière", desc9 : "- FUTURA, Full frame, Magda Denysz, published by Drago, 2019, page 143 \n\n- Futura : the Artist's Monograph, published by Rizzoli International Publications, 2020, p236, 237"  },
        { src: PhotoduMoment2, name: "Invader", artist: "Invader (1969)", title:"Half Phantom, 2003", desc1:"Carreaux de mosaïque sur panneau", desc2:"Mosaic tiles on panel", desc3:"40 x 40 cm", desc4:"15,75 x 15.75 in.", desc5:"Numéro d’inventaire d’Invader : 14995", desc6:"Invader’s inventory n° 14995", desc7: "- Collection particulière, Italie.\n- Magda Danysz Gallery, France.", desc10 :"<h4>- Le certificat d'authenticité de Magda Danysz Gallery sera remis à l'acquéreur\n<i>A certificate of authenticity from Magda Danysz Gallery will be delivered to the buyer</i></h4>" },
        { src: PhotoduMoment3, name: "Keith Haring", artist: "Keith Haring (1958 - 1990)", title:"Untitled Kangaroo, 1984", desc1:"Encre sur papier", desc2:"Ink on paper", desc3:"121 x 85,8 cm", desc4:"48x33 3⁄4 in.", desc5:"132,5 x 97 cm (encadré)", desc6:"52,16 x 38.19 in. (framed)", desc10:"<h4>Certificat d'authenticité de l'Estate Keith Haring New York 12 octobre 1990, n° 101290A6.\n<i>Certificate of authenticity of the Estate Keith Haring New York October 12 1990, n° 101290A6.</i></h4>", desc7:"- Collection particulière.\n- Collection particulière, Allemagne.\n- Collection Lio Malca, États-Unis.,", desc11:"<h4><i>- Keith Haring visits Australia, documentaire réaliséé en 1984 où il a collaboré avec National Gallery of Victoria and the New South Wales Art Gallery</i></h4>"  },
      ],
  };

  // const [email, setEmail] = useState("");

  const [disp, setDisp] = useState(false);
  const [email, setEmail] = useState("");
  const [result, setResult] = useState(null);
  const [fields, setFields] = useState({
    FNAME: '',
    LNAME: ''
  })

  const swapDisp=()=> {
    setDisp((disp) => !disp);
    console.log("disp === " + disp);
  }
  
  // setDisp(false);
  // return (
  //   <div style={{height: "100vh", backgroundColor: "red",display: "flex", position: "relative"}}>
  //      <img src={Landing2} style={{backgroundSize:"contain", height: "100vh", width: "100vw"}}></img>
  //   <div style={{bottom: "0px", position: "absolute", backgroundColor: "white", margin: "10px", padding: "10px"}}>
  //     <p>Anthony Eden Clark A.K.A AONE</p>
  //     <p>Retro 1981-1915</p>
  //     <p>A cura di SHARP</p>
  //   </div>
  // </div>
  // );

  const handleSubmit = async (e) => {
    e.preventDefault();
    const result = await addToMailchimp(email, fields);
    setResult(result);
    console.log("result=>",result.result);
    // but you can do whatever you want
  }

  const handleEmail = async (e) => {
    e.preventDefault();

    setEmail(e.target.value);
    console.log("email=>".email);

  }
  const handleName = async (e) => {
    e.preventDefault();
    setFields({
      FNAME: e.target.value
    });
    console.log("fields=>".fields);
  }
  return (
    <div >
      {/* <div className={ModalPhoneWrapper}>
        <div className={ModalPhoneGrid}>
                <div style={{padding: "50px 0 50px"}}>
                  <h3 style={{fontWeight: "bold"}}>You can check the app for Phone</h3>
                </div>
          </div>
        </div> */}
        <div className={LandingSlider}>

        <Carousel 
        swipeable={false}
        draggable={false}
        // showDots={true}
        slidesToSlide={1}
        responsive={responsive}
        // infinite={true}
        autoPlay={false}
        autoPlaySpeed={2000}
        keyBoardControl={true}
        // customTransition="transform 500ms ease-in-out"
        // transitionDuration={500}
        // containerClass="react-multi-carousel-list"
        // additionalTransfrom={-70}
        partialVisible={false}
        removeArrowOnDeviceType={["tablet", "mobile"]}
        dotListClass="custom-dot-list-style"
        >
      <a href="/fr/expositions/crash-expo">


          <div style={{height: "100vh",display: "flex", position: "relative",paddingTop: "35px"}}>
        <img src={SSLIDE} style={{backgroundSize:"contain", height: "100vh", width: "100vw"}}></img>
      </div>
      </a>
    </Carousel>
        </div>
    {/* <div style={{height: "100vh", backgroundColor: "red",display: "flex", position: "relative"}}>
       <img src={Landing1} style={{backgroundSize:"contain", height: "100vh", width: "100vw"}}></img>
    <div style={{bottom: "0px", position: "absolute", backgroundColor: "white", margin: "10px", padding: "10px"}}>
      <p>Anthony Eden Clark A.K.A AONE</p>
      <p>Retro 1981-1995</p>
      <p>A cura di SHARP</p>
    </div>
  </div> */}
      {/* <div className={CarouselWrapper}>
        <img src={Landing1} style={{position: "relative"}}></img>
        <div style={{backgroundColor: "red", width: "200px", height: "50px", zIndex: "3",position: "absolute", bottom: "200px"}}>
          <p>Hola</p>
        </div>
      </div> */}
      <div className={Wrapper}>
        <br />
        <h3 className={GrayTitle} style={{fontWeight:"bolder", textAlign:"inherit", paddingTop: "60px"}}>Bienvenue chez <span style={{letterSpacing:"2px"}}>GHOST GALERIE</span></h3>
        <br />
          <p style={{fontSize:"16px", textAlign:"justify"}}>Après Marseille, GHOST GALERIE, spécialiste du Post-graffiti old school, s’installe à Paris et ouvre sa deuxième adresse, une adresse de prestige pour promouvoir l’importance d’un mouvement artistique incontournable et majeur dans l’histoire de l’art du XX° siècle.</p>
          <br />
          <p style={{fontSize:"16px", textAlign:"justify"}}>GHOST GALERIE Paris dévoilera ainsi au fil des événements une programmation ouverte, originale et ambitieuse où des oeuvres historiques répondront à des créations plus contemporaines. Des expositions thématiques ou personnelles viendront questionner l’influence que le graffiti et le Post-graffiti ont eu et ont encore aujourd’hui sur la scène contemporaine actuelle.</p>
          <div className={ButtonWrapper} style={{paddingBottom:"30px", paddingTop:"30px"}}>
          <Button 
            alt=""
            onClick={() => {
              if (typeof window !== "undefined") {
                window.location.href = "/fr/a-propos";
              }
            }}
          >
            A propos
          </Button>
        </div>
        <hr className={Separator} />
        <div>
          <Link to='expositions' className={GrayTitle} style={{color:"black", fontSize:"22px", textAlign:"center", display:"block", marginLeft:"auto", marginRight:"auto", marginTop:"30px", marginBottom:"30px"}}>Exposition en cours</Link>
          {/* <h4 className={GrayTitle} style={{color:"black", fontSize:"22px"}}>Prochaine Exposition</h4> */}
          {/* <h5 style={{color:"black"}} >Lorem, ipsum dolor sit amet consectetur adipisicing elit. Optio eos dolor ipsa nisi laudantium consequuntur temporibus tenetur minima molestiae dicta, illo blanditiis. Recusandae aspernatur adipisci ducimus veritatis cumque sapiente et. Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi dignissimos quae repellendus. Molestias omnis laudantium dicta accusantium neque quas facilis ad pariatur rerum illo? Facere expedita molestiae quae blanditiis animi? Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem consequatur soluta sequi, quia ex officia minima eveniet dicta incidunt eaque dignissimos nobis repellat quibusdam accusantium unde aspernatur nisi temporibus voluptatem. Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi voluptatibus impedit animi veniam nisi eos suscipit delectus magnam nemo fugit aliquid ab incidunt error rerum, exercitationem, similique quaerat, eum eius!</h5> */}
          <div className={ListWrapper} style={{backgroundColor:""}}>
            {exhibitions.map((exhibition, i = 0) => (
              <EventCardExpo
              key={1}
                date={exhibition.date}
                title={exhibition.title}
                link={"/fr/expositions/" + exhibition.link}
                place={exhibition.place}
                description={exhibition.description}
                description2={exhibition.description2}
                photo={exhibition.presentation_photo}
                index={i}
                artist={exhibition.artist}
                lenght={exhibitions.length}
              />
            ))}
          </div>
        </div>
        <div>
          {/* <Link to='expositions' className={GrayTitle} style={{color:"black", fontSize:"22px", textAlign:"center", display:"block", marginLeft:"auto", marginRight:"auto", marginTop:"30px", marginBottom:"30px"}}>Exposition à venir</Link> */}
          {/* <h4 className={GrayTitle} style={{color:"black", fontSize:"22px"}}>Prochaine Exposition</h4> */}
          {/* <h5 style={{color:"black"}} >Lorem, ipsum dolor sit amet consectetur adipisicing elit. Optio eos dolor ipsa nisi laudantium consequuntur temporibus tenetur minima molestiae dicta, illo blanditiis. Recusandae aspernatur adipisci ducimus veritatis cumque sapiente et. Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi dignissimos quae repellendus. Molestias omnis laudantium dicta accusantium neque quas facilis ad pariatur rerum illo? Facere expedita molestiae quae blanditiis animi? Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem consequatur soluta sequi, quia ex officia minima eveniet dicta incidunt eaque dignissimos nobis repellat quibusdam accusantium unde aspernatur nisi temporibus voluptatem. Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi voluptatibus impedit animi veniam nisi eos suscipit delectus magnam nemo fugit aliquid ab incidunt error rerum, exercitationem, similique quaerat, eum eius!</h5> */}
          <div className={ListWrapper} style={{backgroundColor:""}}>
            {exhibitionsToCome.map((exhibition, i = 0) => (
              <EventCardExpo
              key={1}
                date={exhibition.date}
                title={exhibition.title}
                link={"/fr/expositions/" + exhibition.link}
                place={exhibition.place}
                description={exhibition.description}
                photo={exhibition.presentation_photo}
                index={i}
                artist={exhibition.artist}
                lenght={exhibitions.length}
              />
            ))}
          </div>
        </div>
        <hr className={Separator} />
        <p className={GrayTitle} style={{color:"black", fontSize:"22px", textAlign:"center", display:"block", marginLeft:"auto", marginRight:"auto", marginTop:"30px", marginBottom:"30px"}}>NewsLetter</p>
        <p>Votre adresse de messagerie est uniquement utilisée pour vous envoyer notre newsletter ainsi que des informations concernant les activités de GHOST galerie. Vous pouvez à tout moment vous désabonner en utilisant le lien ou en nous envoyant un message à info@ghostgalerie.com</p>
        <div style={{display: "flex", justifyContent:"center"}}>

        <form onSubmit={handleSubmit} style={{marginTop: "20px"}}>
        <div className={FormFieldWrapper}>
            <label htmlFor="text">Nom</label>
            <input
              className={InputWrapper}
              type="text"
              placeholder="Entrez votre nom"
              onChange={handleName}
              // onChange={(e) => setTel(e.target.value)}

            />
          </div>
          <div className={FormFieldWrapper}>
            <label htmlFor="text">Email <b style={{color:"red", fontSize:"20px"}}>*</b></label>
            <input
              className={InputWrapper}
              type="text"
              placeholder="Entrez votre email"
              onChange={handleEmail}

              // onChange={(e) => setTel(e.target.value)}
            />
          </div>
          <div className={ButtonWrapper}>
            <Button
            style={{backgroundColor: result !== null && result.result == "error" ? "red": ""}}
            type="submit"
              alt=""
             
            >
              {result === null ? "S'inscrire": result.result == "error" ? "Il y a eu une erreur" : "Inscrit !"}
            </Button>
          </div>
      </form>
      </div>

        </div>
      </div> 
  );
};

export default Landing;