// extracted by mini-css-extract-plugin
export var BlackTile = "Landing-module--BlackTile--jklMe";
export var ButtonLink = "Landing-module--ButtonLink---QzqB";
export var ButtonWrapper = "Landing-module--ButtonWrapper --Pxk8P";
export var Caption = "Landing-module--Caption--wqcrs";
export var CarouselWrapper = "Landing-module--CarouselWrapper--EEPTR";
export var CarrouselWrapper = "Landing-module--CarrouselWrapper--Ln+8v";
export var CartelDiv = "Landing-module--CartelDiv--ub9IX";
export var CartelGrid = "Landing-module--CartelGrid--XussF";
export var CartelImg = "Landing-module--CartelImg---Ld5A";
export var FormFieldWrapper = "Landing-module--FormFieldWrapper--TXwwr";
export var ImageItem = "Landing-module--ImageItem--vThQA";
export var InputWrapper = "Landing-module--InputWrapper--RfLkN";
export var LandingSlider = "Landing-module--LandingSlider--CE3TP";
export var ListWrapper = "Landing-module--ListWrapper--btSB5";
export var ModalPhoneGrid = "Landing-module--ModalPhoneGrid---QOAc";
export var ModalPhoneWrapper = "Landing-module--ModalPhoneWrapper--w8VDO";
export var NewDescription = "Landing-module--NewDescription--axlNI";
export var NewsLetterDescription = "Landing-module--NewsLetterDescription--Ck61u";
export var PhotosWrapper = "Landing-module--PhotosWrapper--TEY2F";
export var PreCartelImg = "Landing-module--PreCartelImg--1eU+O";
export var Separator = "Landing-module--Separator--ZPI4t";
export var Wrapper = "Landing-module--Wrapper--VkVKo";
export var isVisible = "Landing-module--is-visible--h0xki";
export var isnotVisible = "Landing-module--isnot-visible--l6pyn";
export var out = "Landing-module--out--GhJ3Y";
export var zoom = "Landing-module--zoom--Bgb8C";
export var zoomOut = "Landing-module--zoom-out--ZgTAL";